/* MOBILE */
@media (max-width: 700px) {
  .mobile-container {
    width: 100% !important;
    padding: 0 !important;
  }

  .mobile-container div {
    width: 100% !important;
    display: block !important;
    margin: 10px 0 !important;
    padding: 0 !important;
    position: unset !important;
    transform: none !important;
  }

  .mobile-container img {
    width: 100% !important;
    display: block !important;
    margin: 10px 0 !important;
    padding: 0 !important;
    position: unset !important;
    transform: none !important;
  }

  .mobile-container div div {
    width: 100% !important;
    display: block !important;
    margin: 10px 0 !important;
    padding: 0 !important;
    position: unset !important;
    transform: none !important;
  }

  .mobile-container div img {
    width: 100% !important;
    display: block !important;
    margin: 10px 0 !important;
    padding: 0 !important;
    position: unset !important;
    transform: none !important;
  }

  #mobile-padding {
    width: 80vw !important;
    padding: 8vw !important;
  }
}

/* GENERAL */
.story-title-container {
  width: 100%;
  text-align: center;
  color: rgb(140, 167, 183);
  margin-bottom: 2rem;
}

.h-center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.creator {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.title {
  font-size: 0.8rem !important;
}

/* ANNABEL KAJETSKI */
.kajetski-container {
  position: relative;
  width: 80%;
  height: auto;
  margin-bottom: 3rem;
  font-size: 0.8rem;
}

.video-container {
  width: 60%;
  height: auto;
}

.video-legend {
  color: rgb(159, 201, 161);
  margin-top: 1rem;
  font-size: 0.8rem;
  margin-bottom: 10rem;
}

.kajetski-002 {
  margin: 2rem;
  display: inline-block;
  vertical-align: top;
  width: 40%;
  height: auto;
}

.kajetski-03-04-container {
  display: inline-block;
  width: 40%;
  height: auto;
  text-align: right;
  position: absolute;
  right: 0;
}

.kajetski-03-04-img-container {
  width: 100%;
  display: block;
}

.kajetski-003 {
  text-align: right;
  vertical-align: top;
  width: 20%;
  height: auto;
}

.kajetski-004 {
  width: 30%;
  height: auto;
  margin: 20% 0 0 1rem;
}

.kajetski-03-04-text {
  margin-top: 1rem;
  color: rgb(159, 201, 161);
  text-align: right;
}

.kajetski-05-container {
  width: 30%;
  height: auto;
  margin-top: 20rem;
}

.kajetski-005 {
  width: 70%;
  margin-bottom: 1rem;
}

.kajetski-05-text {
  color: rgb(159, 201, 161);
  margin-bottom: 1rem;
}

/* AYA BRACE */
.brace-01-container {
  width: 60%;
  font-size: 0.8rem;
}

.brace-001 {
  width: 100%;
}

.brace-01-legend {
  color: rgb(241, 92, 65);
  width: 100%;
  text-align: right;
  margin-bottom: 2rem;
}

.brace-01-text {
  color: rgb(241, 92, 65);
  margin-bottom: 3rem;
}

.brace-002,
.brace-003 {
  display: inline-block;
  width: 35%;
  margin: 2rem 0 6rem 2rem;
}

.brace-04-container {
  width: 100%;
  text-align: right;
}

.brace-004 {
  width: 50%;
  margin-right: 2rem;
}

.brace-05-container {
  margin-top: 5rem;
  width: 100%;
  text-align: center;
}

.brace-005 {
  width: 20%;
}

.brace-006 {
  width: 15%;
  margin: 4rem 0 0 4rem;
}

.brace-07-container {
  width: 100%;
  margin-top: -4rem;
  text-align: right;
}

.brace-007 {
  width: 50%;
  margin-right: 3rem;
}

.brace-008 {
  width: 45%;
  margin: 6rem 0 0 7rem;
}

.brace-09-container {
  width: 100%;
  margin-top: -6rem;
  text-align: right;
}

.brace-009 {
  width: 20%;
  margin-right: 20%;
}

.brace-10-11-container {
  margin-top: 3rem;
  width: 100%;
  vertical-align: top;
}

.brace-010,
.brace-011 {
  display: inline-block;
}

.brace-011 {
  width: 25%;
  margin-left: 3rem;
}

.brace-010 {
  width: 15%;
  vertical-align: top;
  margin-left: 50%;
}

.brace-12-container {
  width: 75%;
  margin-bottom: 4rem;
  margin-top: 4rem;
}

.brace-012 {
  width: 50%;
  display: inline-block;
}

.brace-12-text-container {
  display: inline-block;
  width: 40%;
  margin-left: 5%;
  color: rgb(241, 92, 65);
  font-size: 0.8rem;
}

/* CHRISTINE VALTONEN */
.valtonen-container {
  margin-bottom: 5rem;
}

.valtonen-01-03-container {
  margin: 3rem 0 3rem 0;
  width: 100%;
  text-align: center;
}

.valtonen-01-03-container img {
  width: 25%;
  margin: 1rem;
}

.valtonen-004,
.valtonen-005-container,
.valtonen-006 {
  display: inline-block;
  margin: 6rem;
}

.valtonen-004 {
  width: 30%;
  vertical-align: top;
}

.valtonen-005-container {
  width: 20%;
}

.valtonen-005 {
  width: 100%;
  margin-bottom: 6rem;
}

.valtonen-05-text {
  margin: 4rem 0 8rem 0;
  text-align: left;
  color: rgb(241, 92, 65);
  font-size: 0.8rem;
}

.valtonen-006 {
  width: 10%;
  vertical-align: bottom;
}

.valtonen-07-container {
  width: 20%;
  margin-left: 15%;
  display: inline-block;
}

.valtonen-007 {
  width: 100%;
}

.valtonen-07-text {
  margin-top: 3rem;
  font-size: 0.8rem;
  width: 100%;
  color: rgb(241, 92, 65);
}

.valtonen-008 {
  display: inline-block;
  width: 35%;
  margin: 10% 0 0 20%;
}

/* EEVA SUUTARI */
.suutari-container {
  padding-bottom: 5rem;
  color: rgb(140, 167, 183);
  font-size: 0.7rem;
}

.suutari-container img {
  display: block;
}

.suutari-001 {
  width: 30%;
  margin-left: 30%;
}

.suutari-002 {
  width: 15%;
  margin: -5vh 0 0 75%;
}

.suutari-003 {
  width: 15%;
  margin: -5vh 0 0 10%;
}

.suutari-004 {
  width: 25%;
  margin: -6vh 0 5rem 40%;
}

.suutari-005 {
  display: inline-block !important;
  width: 30%;
  margin: 0 5%;
}

.suutari-text {
  display: inline-block;
  vertical-align: bottom;
  width: 25%;
}

.suutari-tab-left,
.suutari-tab-right {
  display: inline-block;
  width: 30%;
}

/* EMMA SARPANIEMI */
.sarpaniemi-container {
  padding-bottom: 5rem;
  color: rgb(140, 167, 183);
  font-size: 0.7rem;
}

.sarpaniemi-01-02-container {
  width: 100%;
  text-align: center;
}

.sarpaniemi-001,
.sarpaniemi-002 {
  display: inline-block;
  margin: 2rem;
  vertical-align: middle;
}

.sarpaniemi-001 {
  width: 30%;
}

.sarpaniemi-002 {
  width: 25%;
  margin-right: 5%;
}

.sarpaniemi-03-04-container {
  width: 100%;
  text-align: center;
  margin-top: 5rem;
}

.sarpaniemi-003,
.sarpaniemi-004 {
  display: inline-block;
  margin: 2rem;
  vertical-align: middle;
}

.sarpaniemi-003 {
  width: 25%;
  margin-right: 7%;
}

.sarpaniemi-004 {
  width: 30%;
}

.sarpaniemi-005 {
  width: 50%;
  margin: 10rem 0 3rem 0;
}

.sarpaniemi-text-container {
  width: 50%;
  text-align: left;
}

.sarpaniemi-text {
  width: 50%;
}

/* GURLS */
.gurls-container {
  padding-bottom: 5rem;
}

.gurls-001 {
  width: 30%;
}

.gurls-02-container {
  width: 100%;
  text-align: right;
  margin-top: 4rem;
}

.gurls-002 {
  width: 15%;
  margin: 0 10rem 5rem 0;
}

.gurls-003,
.gurls-03-text {
  display: inline-block;
}

.gurls-003 {
  width: 45%;
  margin-left: 10rem;
}

.gurls-03-text {
  width: 30%;
  color: rgb(212, 182, 255);
  font-size: 0.8rem;
  margin-left: 5%;
  vertical-align: top;
}

.gurls-004 {
  margin: 5rem 0 0 5rem;
  width: 15%;
}

.gurls-05-06-text,
.gurls-005,
.gurls-006 {
  display: inline-block;
}

.gurls-05-06-text {
  width: 30%;
  color: rgb(212, 182, 255);
  font-size: 0.8rem;
  margin: 0 5rem 5rem 10rem;
}

.gurls-005 {
  width: 30%;
}

.gurls-006 {
  width: 15%;
  margin-left: 3rem;
  vertical-align: bottom;
}

/* HALIZ YOSEF */
.yosef-container {
  padding: 5rem;
  color: rgb(140, 167, 183);
  font-size: 0.8rem;
}

.yosef-video-legend {
  width: 40%;
  margin-top: 4rem;
}

.yosef-002 {
  width: 20%;
  margin: 5rem;
}

.yosef-02-text {
  text-align: right;
  margin-left: 65%;
  width: 20%;
}

/* JUULIA KRAFT */
.kraft-container {
  padding-bottom: 5rem;
  color: rgb(140, 167, 183);
  font-size: 0.7rem;
}

.kraft-001 {
  width: 12.5%;
  margin: 3rem 0 3rem 25%;
}

.kraft-text-1 {
  width: 60%;
  max-width: 700px;
  margin-left: 25%;
}

.kraft-02-03-container {
  width: 100%;
  margin: 3rem 3rem 0 0;
  text-align: center;
}

.kraft-02-container,
.kraft-003 {
  width: 25%;
  vertical-align: middle;
  display: inline-block;
}

.kraft-02-container {
  text-align: left;
}

.kraft-002 {
  width: 70%;
  margin: 0;
  margin-bottom: 2rem;
}

.kraft-003 {
  position: relative;
  left: 5%;
}

.kraft-04-container {
  width: 100%;
  text-align: right;
  margin-top: 3rem;
}

.kraft-004 {
  width: 25%;
  margin-right: 3rem;
}

.kraft-instruction-container {
  width: 15%;
  display: inline-block;
  vertical-align: top;
}

.kraft-instruction-container img {
  width: 100%;
  margin-bottom: 1rem;
}

.kraft-instruction-text {
  width: 100%;
}

.kraft-instruction-1 {
  margin: 0 2% 0 5%;
}

.kraft-instruction-2 {
  margin-top: 3%;
}

.kraft-instruction-3 {
  margin: 15% 2% 0 20%;
}

.kraft-instruction-4 {
  margin-top: 15%;
}

.kraft-09-11-container {
  width: 100%;
  text-align: center;
}

.kraft-09-10-container,
.kraft-11-container {
  width: 25%;
  display: inline-block;
  text-align: right;
  vertical-align: top;
}

.kraft-009 {
  width: 100%;
}

.kraft-010,
.kraft-011 {
  width: 80%;
}

.kraft-011 {
  margin-top: 30%;
}

.kraft-11-text {
  text-align: left;
  margin: 3rem -10rem 0 10rem;
}

.kraft-09-text,
.kraft-10-text {
  margin: 0.5rem 0 1rem;
}

/* LANDYS ROIMOLA */
.roimola-container {
  font-size: 0.7rem;
  color: rgb(212, 182, 255);
}

.roimola-video-legend {
  width: 100%;
  margin: 6rem 0;
}

.roimola-video-text {
  width: 70%;
  text-align: left;
}

.roimola-002 {
  width: 60%;
  margin: 4rem 0;
}

.roimola-text {
  width: 45%;
  text-align: right;
  margin: 6rem 0;
}

/* MAISA IMMONEN */
.immonen-container {
  padding: 5rem;
}

.immonen-001 {
  width: 30%;
  margin-bottom: 5rem;
}

.immonen-video-legend {
  position: relative;
  width: 100%;
  text-align: right;
  color: rgb(212, 182, 255);
  font-size: 0.8rem;
  margin-top: 3rem;
  height: 20vh;
}

.immonen-text {
  position: absolute;
  right: 0;
  width: 50%;
}

@media (max-width: 700px) {
  #immonen-mobile-text {
    margin-top: -5% !important;
  }
}

/* MAN YAU & MARIA TASULA */
.yautasula-container {
  color: rgb(241, 92, 65);
  font-size: 0.8rem;
}

.yautasula-text-1 {
  width: 50%;
  margin: 15rem 0;
  text-align: center;
}

.yautasula-001,
.yautasula-002 {
  display: inline-block;
  vertical-align: bottom;
}

.yautasula-001 {
  width: 35%;
  margin: 10% 3% 5% 15%;
}

.yautasula-002 {
  width: 25%;
}

.yautasula-text-2 {
  margin: 5rem 0 3rem 3rem;
  width: 60vw;
}

.yautasula-text-3 {
  margin-left: 10rem;
  text-align: right;
  width: 50vw;
}

.yautasula-003 {
  width: 25%;
  margin-left: 60%;
  margin-top: 10rem;
}

.yautasula-004 {
  width: 50%;
  margin: 7rem 0 3rem 0;
}

.yautasula-text-4 {
  width: 70%;
  text-align: center;
}

.yautasula-05-container {
  width: 100%;
  text-align: center;
  margin-top: 10%;
}

.yautasula-text-5,
.yautasula-005,
.yautasula-007,
.yautasula-text-7 {
  display: inline-block;
  margin: 5%;
  vertical-align: middle;
}

.yautasula-text-5 {
  width: 50%;
}

.yautasula-005 {
  width: 30%;
}

.yautasula-006 {
  width: 30%;
  margin: 10% 0 10% 10%;
}

.yautasula-text-6 {
  width: 60%;
  text-align: right;
  margin-left: 35%;
  margin-bottom: 5%;
}

.yautasula-007 {
  width: 30%;
  margin: 0 0 0 20%;
}

.yautasula-text-7 {
  text-align: center;
  width: 40%;
}

.yautasula-text-8 {
  text-align: left;
  margin: 5%;
}

.yautasula-text-9 {
  width: 40%;
  margin: 10% 0 25% 0;
}

/* RONYA HIRSMA */
.hirsma-container {
  padding: 5rem 5rem 10rem 5rem;
  color: rgb(140, 167, 183);
  font-size: 0.7rem;
}

.hirsma-01-02-container {
  width: 100%;
  margin-top: 5rem;
}

.hirsma-001,
.hirsma-01-02-text-container,
.hirsma-002 {
  display: inline-block;
}

.hirsma-001 {
  width: 15%;
  margin: 0 -5% 0 10%;
  vertical-align: bottom;
}

.hirsma-01-02-text-container {
  width: 35%;
  text-align: left;
}

.hirsma-002 {
  width: 30%;
  vertical-align: top;
  margin-left: 5%;
}

.hirsma-01-02-text-container div {
  margin-bottom: 4rem;
}

.hirsma-text-2 {
  margin-left: 20%;
}

.hirsma-text-3 {
  margin-left: 40%;
}

.hirsma-text-4 {
  margin-left: 60%;
}

.hirsma-text-5 {
  margin-left: 80%;
}

.hirsma-text-6 {
  margin: 5rem 0 5rem 50%;
  width: 50%;
  text-align: left;
}

.hirsma-text-7,
.hirsma-003 {
  display: inline-block;
}

.hirsma-text-7 {
  width: 15%;
  margin: 0 35% 5% 5%;
  vertical-align: bottom;
}

.hirsma-003 {
  width: 35%;
}

/* SIDONIE RONFARD */
.ronfard-container {
  padding: 5rem 0 10rem 0;
  color: rgb(241, 92, 65);
  font-size: 0.7rem;
}

.ronfard-01-02-container {
  width: 100%;
  text-align: center;
}

.ronfard-01-02-text,
.ronfard-001,
.ronfard-002 {
  display: inline-block;
}

.ronfard-01-02-text {
  width: 20%;
  vertical-align: bottom;
  text-align: left;
  margin-bottom: 3%;
}

.ronfard-001 {
  width: 20%;
  margin: 5% 10% 0 10%;
}

.ronfard-002 {
  vertical-align: top;
  width: 15%;
}

.ronfard-03-05-06-container {
  display: inline-block;
  width: 50%;
  text-align: center;
  margin-top: 10rem;
}

.ronfard-003 {
  width: 40%;
  display: block;
  margin-bottom: 10rem;
}

.ronfard-006,
.ronfard-005 {
  display: inline-block;
}

.ronfard-005 {
  width: 35%;
  margin: 10% 15%;
}

.ronfard-006 {
  width: 20%;
  vertical-align: top;
}

.ronfard-04-07-container {
  vertical-align: top;
  display: inline-block;
  width: 45%;
  margin-left: 5%;
}

.ronfard-004 {
  width: 90%;
  margin-top: 10rem;
}

.ronfard-007 {
  width: 50%;
  position: relative;
  left: 40%;
  margin-top: 25%;
}

.ronfard-008,
.ronfard-009,
.ronfard-010,
.ronfard-011,
.ronfard-012,
.ronfard-12-text {
  display: inline-block;
}

.ronfard-008 {
  width: 30%;
  margin: 5% 2% 5% 10%;
}

.ronfard-009 {
  width: 15%;
  vertical-align: bottom;
}

.ronfard-010 {
  width: 15%;
  margin-left: 20%;
  vertical-align: top;
}

.ronfard-011 {
  width: 20%;
  vertical-align: bottom;
  margin: 0% 2% 0 5%;
}

.ronfard-012 {
  width: 30%;
  vertical-align: bottom;
  margin: 10% 0 5% 0;
}

.ronfard-12-text {
  width: 20%;
  vertical-align: bottom;
  margin: 0 0 20% 10%;
}

/* SANNA SILANDER */
.silander-container {
  color: rgb(241, 92, 65);
  font-size: 0.6rem;
}

.silander-001 {
  width: 40%;
}

.silander-002,
.silander-003 {
  display: inline-block;
  vertical-align: top;
}

.silander-002 {
  width: 15%;
}

.silander-003 {
  width: 30%;
  margin: 5% 45% 10% 5%;
}

.silander-004 {
  width: 45%;
  margin-left: 45%;
}

.silander-005,
.silander-006 {
  display: block;
  width: 50%;
  margin: 10% 0;
}

.silander-007,
.silander-008,
.silander-009,
.silander-010 {
  display: inline-block;
  vertical-align: bottom;
}

.silander-007 {
  width: 40%;
  margin: 10% 25% 20% 10%;
}

.silander-008 {
  width: 20%;
  margin-bottom: 20%;
}

.silander-009 {
  width: 20%;
  margin: 0 30% 0 5%;
}

.silander-010 {
  width: 30%;
}

.silander-11-container {
  display: inline-block;
  width: 35%;
  margin: 10% 20% 0 5%;
  vertical-align: top;
}

.silander-tab {
  margin: 20% 0;
}

.silander-tab-left,
.silander-tab-right {
  display: inline-block;
  vertical-align: top;
  margin-right: 5%;
}

.silander-011 {
  width: 80%;
  margin-bottom: 20%;
}

.silander-012 {
  vertical-align: top;
  display: inline-block;
  width: 30%;
  margin-top: 10%;
}

@media (max-width: 700px) {
  #silander-mobile-tab {
    display: inline-block !important;
    vertical-align: top;
    margin-left: 8vw !important;
    width: 25% !important;
  }
}

/* TAIKA MANNILA */
.mannila-container {
  color: rgb(212, 182, 255);
  font-size: 0.7rem;
  text-align: left;
}

.mannila-container div {
  width: 30%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.mannila-container img {
  width: 40%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.mannila-001 {
  margin-left: 30%;
}

.mannila-002 {
  margin-left: 50%;
}

.mannila-text-4 {
  width: 20% !important;
  left: 10% !important;
  transform: none !important;
  margin: 5rem 0;
}

/* VANESSA FORSTEN */
.forsten-container {
  padding-bottom: 5rem;
  color: rgb(241, 92, 65);
  font-size: 0.7rem;
}

.forsten-001 {
  width: 60%;
}

.forsten-002,
.forsten-03-04-container {
  vertical-align: top;
  display: inline-block;
  width: 35%;
  margin: 7.5%;
}

.forsten-003 {
  width: 100%;
  margin-top: 30%;
}

.forsten-004 {
  width: 100%;
  margin-top: 5%;
}

.forsten-005 {
  display: block;
  width: 30%;
  margin-bottom: 5rem;
}

.forsten-text,
.forsten-006 {
  display: inline-block;
  vertical-align: middle;
}

.forsten-text {
  width: 25%;
  margin: 0 20% 0 5%;
}

.forsten-006 {
  width: 40%;
}
