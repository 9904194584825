.main-page {
  font-family: "GlacialIndifference";
  position: relative;
  border: 15px solid rgb(159, 201, 161);
}

.main-content {
  width: 100%;
}

.mobile-publication-title,
.mobile-publication-creator {
  display: none;
}

/* TOP OF PAGE */
#top-container {
  position: relative;
}

#logo-container {
  display: inline-block;
  margin: 3rem;
}

#logo-title,
#logo-legend {
  color: rgb(241, 92, 65);
}

#logo-title {
  font-size: 2.5em;
  cursor: pointer;
}

#logo-legend {
  margin: 0;
}

.links-container {
  display: inline-block;
  position: absolute;
  right: 0;
  height: 100%;
  padding-right: 3rem;
}

.links {
  color: rgb(212, 182, 255);
  font-size: 1.2em;
  display: inline;
  margin: 1em;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* HAMBURGER */
.hamburger-logo {
  display: none;
}

.hamburger-menu {
  display: none;
}

/* CONTRIBUTORS, ABOUT */
.contributors-container,
.about-container {
  color: rgb(212, 182, 255);
  min-height: 100vh;
}

#contributors-title,
#about-title {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}

#about-text,
#contributors-list {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#contributors-list {
  width: 10%;
}

#about-title {
  font-size: 1.5rem;
}

#about-text {
  width: 30%;
}

/* CONTACT */
.contact-container {
  width: 100vw;
  height: 100vh;
  text-align: center;
  color: rgb(212, 182, 255);
}

.contact-title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.contact-tab-left,
.contact-tab-right {
  display: inline-block;
  text-align: left;
}

.contact-tab-left {
  margin-right: 2rem;
}

/* PUBLICATIONS */
.publications-container {
  position: relative;
  width: 70%;
  min-height: 150vh;
  margin-bottom: 30%;
}

.publication {
  height: 250px;
}

#pub-image {
  width: auto;
  height: 100%;
  opacity: 0;
  z-index: 1;
}

#pub-creator {
  color: rgb(140, 167, 183);
  font-size: 1.2em;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
}

/* INFO CONTAINER */
.info-container {
  margin: 0;
  width: 100%;
  height: auto;
  background-color: rgb(254, 224, 173);
  padding: 2rem 0;
}

#info-title,
#info-text {
  margin: 1rem 0 1rem 3rem;
  color: rgb(195, 155, 255);
}

#info-title {
  margin-top: 2rem;
  font-size: 2.5em;
}

#info-text {
  width: 15rem;
  font-size: 0.8rem;
  margin-top: 1rem;
}

#info-copyright {
  color: rgb(140, 167, 183);
  font-size: 0.8rem;
  width: 98%;
  text-align: right;
}

/* MOBILE */
@media (max-width: 700px) {
  #top-container {
    position: fixed;
    background-color: white;
    z-index: 20;
    width: 96vw;
    height: 6vh;
    top: 0;
    left: 0;
    border: 2vw solid rgb(159, 201, 161);
    border-bottom: none;
  }

  .main-content {
    margin-top: 6vh;
  }

  .about-container,
  .contact-container {
    width: 96vw;
    margin-top: 20%;
  }

  #about-text {
    width: 70%;
  }

  .hamburger-logo {
    display: inline-block;
    position: absolute;
    right: 0;
    height: 100%;
  }

  .hamburger-logo img {
    width: 1rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 1rem;
  }

  .hamburger-menu {
    display: block;
    height: 93vh;
    width: 96vw;
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 7vh;
    left: 2vw;
    z-index: 20;
  }

  .hamburger-links-container {
    text-align: right;
  }

  .hamburger-links {
    color: rgb(195, 155, 255);
    margin: 1rem;
  }

  .main-page {
    border: 2vw solid rgb(159, 201, 161);
  }

  #logo-container {
    margin: 0.5rem;
  }

  #logo-title {
    font-size: 2vh;
  }

  #logo-legend {
    font-size: 1.5vh;
  }

  .links-container {
    display: none;
  }

  .publications-container {
    height: auto;
    width: 100%;
    margin-bottom: 5% !important;
  }

  .mobile-publication-title,
  .mobile-publication-creator {
    display: block;
    font-size: 0.7rem;
    color: rgb(140, 167, 183);
    margin-left: 0.5rem;
  }

  .mobile-publication-creator {
    margin-bottom: 1rem;
  }

  .publication {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  #pub-image {
    width: 100%;
    height: auto;
    z-index: 2;
    cursor: pointer;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1 !important;
  }

  #pub-creator,
  #pub-title {
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  #pub-creator {
    display: block;
  }

  #pub-title {
    text-align: left;
    position: relative;
    top: 0;
    width: 100%;
    margin-bottom: 2rem;
  }

  .info-container {
    padding-top: 1rem;
    position: relative;
  }

  #info-copyright {
    margin: 2rem 1rem -1rem 0 !important;
    text-align: right;
    font-size: 0.6rem;
  }
}
