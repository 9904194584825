@font-face {
  font-family: "GlacialIndifference";
  src: local("GlacialIndifference"),
    url(/Fonts/GlacialIndifference-Regular.otf) format("opentype");
}

html,
body {
  font-family: "GlacialIndifference";
}

a {
  text-decoration: none;
  color: inherit;
}
