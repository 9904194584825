.loading-screen {
	width: 100%;
	height: 100vh;
	background-color: rgba(212, 182, 255, 1);
	position: absolute;
	z-index: 1000;
}

.loading-logo {
	width: 100%;
	text-align: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	font-size: 3rem;
	color: rgb(241, 92, 65);
}
