.contributors-container {
  width: 40%;
  text-align: center;
  margin-bottom: 5rem;
}

.contributors-container span {
  cursor: pointer;
}

.contributors-title {
  font-size: 1.5rem;
  margin-bottom: 3rem;
}

.contributors-tab-left,
.contributors-tab-right {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.contributors-tab-left {
  margin-right: 2rem;
}

@media (max-width: 700px) {
  .contributors-container {
    width: 100%;
    margin-top: 20%;
  }
}
