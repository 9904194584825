.frontpage {
  width: 100vw;
  height: 100vh;
  text-align: center;
  color: rgb(241, 92, 65);
  border: 15px solid rgb(159, 201, 161);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "GlacialIndifference";
}

.frontpage-title {
  font-size: 2rem;
  margin-top: 10%;
}

.frontpage-legend {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.frontpage-image {
  width: 20%;
}

.frontpage-enter {
  font-size: 1.5rem;
  margin-top: 1rem;
  cursor: pointer;
}

.title-container,
#title {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title-container {
  position: relative;
  height: 50vh;
  width: 60vw;
  border: 1px solid rgb(0, 0, 0);
}

#title {
  position: absolute;
  color: rgb(241, 92, 65);
  font-size: 4em;
}

@media (max-width: 700px) {
  .frontpage-image {
    width: 80vw;
  }
}
